<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="jobHandlerNameList">
              <input-plus
                v-model="searchForm.jobHandlerNameList"
                valueType="Array"
                placeholder="任务处理器"
                title="任务处理器"
                label="任务处理器"
                clearable
              ></input-plus>
            </el-form-item>
            <el-form-item class="search-item--1" prop="jobHandlerDesc">
              <el-input
                v-model="searchForm.jobHandlerDesc"
                placeholder="任务描述"
                title="任务描述"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="requestId">
              <el-input
                v-model="searchForm.requestId"
                placeholder="请求ID"
                title="请求ID"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="processed">
              <e6-vr-select
                v-model="searchForm.processed"
                :data="processedList"
                placeholder="是否已处理"
                title="是否已处理"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="executeResultList">
              <e6-vr-select
                v-model="searchForm.executeResultList"
                :data="TaskExecuteResultList"
                multiple
                placeholder="执行结果"
                title="执行结果"
                :props="{
                  id: 'codeValue',
                  label: 'codeName',
                }"
                virtual
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--2" prop="executeDay">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="执行日期"
                ref="effectiveDatePicker"
                v-model="searchForm.executeDay"
                title="执行日期"
                :picker-options="pickerOptions('searchForm.executeDay')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="执行日期（始）"
                end-placeholder="执行日期（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <el-button
            type="primary"
            title="更新失败处理方式"
            @click="handleUpdate"
          >
            更新失败处理方式
          </el-button>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          @selection-change="handleSelectionChange"
          highlight-current-row
          ref="elTable"
          row-key="consumeDetailId"
        >
          <el-table-column type="selection" width="50"></el-table-column>
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="80"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{ scope.$index + 1 }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="100"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList(scope.row)"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <el-dialog
      v-dialogDrag
      title="提示"
      :visible="addVisible"
      width="500px"
      :close-on-click-modal="false"
      v-loading="dialogLoading"
      :element-loading-background="loadingBackground"
      :before-close="handleClose"
      custom-class="install-scene-dialog"
    >
      <el-form
        label-width="120px"
        ref="addForm"
        :inline="true"
        :model="addForm"
      >
        <el-form-item label="失败处理方式" prop="exceptionProcessDesc">
          <el-input v-model="addForm.exceptionProcessDesc"></el-input>
        </el-form-item>
      </el-form>

      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import inputPlus from "@/components/inputPlus";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import {
  listDetailSumPage,
  findDownList,
  updateLsistDetailSumPage,
  exportLsistDetailSumPage,
} from "@/api";
import { printError } from "@/utils/util";
import { exportData } from "@/utils/download";
export default {
  name: "scheduledTaskLogDetail",
  components: { inputPlus },
  data() {
    return {
      total: 0,
      loading: false,
      searchForm: {
        requestId: "",
        processed: "",
        jobHandlerDesc: "",
        executeResultList: [],
        jobHandlerNameList: [],
        executeDayStart: "",
        executeDayEnd: "",
        executeDay: [],
        pageIndex: 1,
        pageSize: 20,
      },
      processedList: [
        {
          id: 0,
          label: "否",
        },
        {
          id: 1,
          label: "是",
        },
      ],
      columnData: [
        {
          fieldName: "logId",
          display: true,
          fieldLabel: "主键",
          width: 100,
          disabled: false,
          fixed: true,
          align: "center",
        },
        {
          fieldName: "requestId",
          display: true,
          fieldLabel: "请求ID",
          width: 160,
          disabled: false,
          fixed: true,
          align: "center",
        },
        {
          fieldName: "jobHandlerName",
          display: true,
          fieldLabel: "任务处理器",
          width: 160,
          disabled: false,
          fixed: true,
          align: "center",
        },
        {
          fieldName: "jobHandlerDesc",
          display: true,
          fieldLabel: "任务描述",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "executeBeginTime",
          display: true,
          fieldLabel: "执行开始时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "executeEndTime",
          display: true,
          fieldLabel: "执行结束时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "executeResult",
          display: true,
          fieldLabel: "执行结果",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "executeResultDesc",
          display: true,
          fieldLabel: "执行结果描述",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "exceptionProcessDesc",
          display: true,
          fieldLabel: "异常处理方式",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center",
        },
      ], //表头
      TaskExecuteResultList: [],
      tableData: [],
      refTable: "elTable",
      queryListAPI: listDetailSumPage,
      editType: "",
      clickId: "",
      addForm: {},
      addVisible: false,
      selColumnId: [],
      dialogLoading: false,
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {
    "searchForm.executeDay": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.executeDay = this.searchForm.executeDay.reverse();
        }
        this.searchForm.executeDayStart = val[0] || "";
        this.searchForm.executeDayEnd = val[1] || "";
      },
    },
  },
  created() {
    this.initData();
    this.initForm();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {},
  methods: {
    async initData() {
      try {
        this.dialogLoading = true;
        let promiseList = [findDownList(["TaskExecuteResult"])];
        let [res] = await Promise.all(promiseList);
        this.TaskExecuteResultList = this.getFreezeResponse(res, {
          path: "data.TaskExecuteResult",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    initForm() {
      let hasParamsRoute = this.getHasParamsRoute();
      let executeDay = hasParamsRoute.params.executeDay;
      var date = new Date(executeDay); // 将时间戳转换为日期对象
      date.setDate(date.getDate() + 1); // 在日期上加一天
      let nextDay = date.getTime();
      this.searchForm.executeDay = [executeDay, nextDay];
      this.$nextTick(() => {
        this.searchForm.jobHandlerNameList =
          hasParamsRoute.params.jobHandlerNameList;
      });
      this.queryList();
    },
    // 表格勾选时间
    handleSelectionChange(columns) {
      this.selColumnId = columns.map((item) => {
        return item.logId;
      });
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "详情",
          id: 1,
        },
        {
          label: "编辑",
          id: 2,
        },
      ];
      return operates;
    },
    // 操作--更多
    handleOperate(val, row) {
      this.addVisible = true;
      if (val.id == 1) {
        this.editType = 1;
      }
      if (val.id == 2) {
        this.editType = 2;
      }
      this.clickId = row.releaseRecordId;
    },
    handleAdd() {
      this.addVisible = true;
      this.editType = 0;
    },
    closeDialog(prop) {
      this[prop] = false;
    },
    //点击启用、禁用
    handleUpdate() {
      if (!this.selColumnId.length) {
        this.$message.warning("请选择要操作的数据");
        return;
      }
      this.addVisible = true;
    },
    confirm() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.update();
        }
      });
    },
    handleClose() {
      this.addVisible = false;
      this.addForm = {
        exceptionProcessDesc: "",
      };
    },
    async update() {
      try {
        this.loading = true;
        let res = await updateLsistDetailSumPage({
          logIdList: this.selColumnId,
          exceptionProcessDesc: this.addForm.exceptionProcessDesc,
        });
        if (res.code == "OK") {
          this.$message.success("更新成功");
          this.selColumnId = [];
          this.queryList();
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //  导出
    exportData() {
      exportData(this, exportLsistDetailSumPage);
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.install-scene-dialog {
  .el-dialog__body {
    padding-top: 20px;
    [class^="e6-icon"] {
      height: 28px;
      color: #46bfea;
      line-height: 28px;
      font-style: normal;
      text-align: center;
      padding: 0 10px;
      cursor: pointer;
      border-radius: 3px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 28px;
      padding: 0;
      font-size: 20px;
    }
  }
  .el-form {
    .el-input {
      width: 300px;
    }
  }
}
</style>
